import { Avatar, Box, Card, CardContent, Typography } from '@mui/material';
import BookButtonWhite from '../BookButtonWhite';
import styles from './ReviewCard.module.css';
import { useState } from 'react';
import OrderDialog from '../../dialogs/OrderDialog';

const ReviewCard = ({ url, name, text }) => {
  const [showDialog, setShowDialog] = useState(false);
  
  return (
    <div className={styles.sliderItemBox}>
      <Avatar src={url} sx={{ width: 150, height: 150, position: "absolute", top: '0px', left: '50%', transform: 'translateX(-50%)' }} />
      <Card>
        <CardContent sx={{paddingTop: '90px'}}>
          <Typography align="center" sx={{ fontSize: 20, fontWeight: 500 }}>
            { name }
          </Typography>
          <Typography align="center" sx={{mt:3}} className={styles.text}>
            { text }
          </Typography>
          <Box sx={{mt:3}} align="center">
            <BookButtonWhite buttonColor="reviewButton" onClick={() => setShowDialog(true)}/>
          </Box>
        </CardContent>
      </Card>
      { showDialog && <OrderDialog onClose={() => setShowDialog(false)} open={showDialog} /> }
    </div>
  );
};

export default ReviewCard;