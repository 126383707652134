import * as React from 'react';
import styles from "./DaySchedule.module.css";
import { CircularProgress, Divider, styled } from "@mui/material";
import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import { useQuery } from 'react-query';
import QUERY_KEYS from '../../../../net/query-keys';
import REQUESTS from '../../../../net/requests';
import ServerError from '../../../../components/widgets/ServerError/ServerError';
import ScheduleInfo from './ScheduleInfo';

dayjs.extend(isToday);

const DaySchedule = ({ scheduleIds, masters, schedules }) => {

  const cacheKey = QUERY_KEYS.recordsOfSchedulesWithIds(scheduleIds);
  const recordsRequest = useQuery(cacheKey, REQUESTS.getRecordsOfSchedulesWithIds(scheduleIds), { refetchOnMount: true });

  if (recordsRequest.isLoading) {
    return <CircularProgress />
  }
  if (recordsRequest.isError) {
    return <ServerError/>
  }
  
  const sortSchedules = (info1, info2) => {
    const schedule1 = schedules.find(schedule => schedule.id === info1.id);
    const schedule2 = schedules.find(schedule => schedule.id === info2.id);
    return schedule1.masterId - schedule2.masterId;
  }

  return (
    <div className={styles.scheduleRecords}>
      {
        recordsRequest.data.length > 0 ? 
          recordsRequest.data.sort(sortSchedules).map(item => <React.Fragment key={item.id}>
            <Divider orientation="horizontal" variant="middle" flexItem />
              <ScheduleInfo info={item} masters={masters} schedule={schedules.find(schedule => schedule.id === item.id)} />
            </React.Fragment>) 
          : <p>No records here</p>
      }
    </div>
  );
};

export default DaySchedule;
