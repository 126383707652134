const pl = {
  topMenuButtonName: 'Menu',
  topMenuMain: 'Główna',
  topMenuServices: 'Procedury',
  topMenuFirstTime: 'Pierwszy raz',
  topMenuForWoman: 'Dla kobiet',
  topMenuForMan: 'Dla mężczyzn',
  topMenuForBody: 'Dla ciała',
  topMenuConsultations: 'Konsultacje',
  topMenuSubscriptions: 'Subskrypcje',
  topMenuRecords: 'Zamów',
  topMenuContacts: 'Kontakty',
  topMenuGiftCards: 'Bony podarunkowe',
  topMenuBookButtonText: 'Umówić się',
  topMenuAdvices: 'Porada kosmetologa', 

  serviceItemTime: 'CZAS',
  serviceItemTimeMin: 'min',
  serviceItemPrice: 'CENA',
  
  bookButtonText: 'Umówić się',
  serviceRecommendations: 'Wskazania',
  serviceTime: 'Czas procedury',

  risksExtraInformation: 'Dodatkowe informacje',
  risksRecommendations: 'Wskazania',
  risksRisks: 'Przeciwwskazania',

  timeMin: 'min',

  accountInfoProfile: 'Profil',
  accountInfoLogin: 'Zaloguj sie',
  accountInfoLogout: 'Wyloguj',
  accountInfoRegister: 'Rejestracja',

  bookDialogNoEmptySlots: 'Wszystkie przedziały czasowe są obecnie zarezerwowane na nagrywanie.',
  bookDialogNoMastersFound: 'nie znaleziono kosmetologów dla tej usługi',
  bookDialogContinue: 'Kontynuować',
  bookDialogTotal: 'Łącznie',
  bookDialogCongrats: 'Gratulujemy!',

  bookDialogFinalYouHaveRecordNext: 'Twój kolejny wpis',
  bookDialogFinalYouHaveRecord: 'Jesteś zarejestrowany',
  bookDialogFinalMaster: 'Kosmetolog',

  questionnaireShare: 'Podziel się z nami swoimi doświadczeniami i odpowiedz na kilka pytań',
  questionnaireNewQuestions: 'Daj nam odpowiedzi na nowe pytania',
  questionnaireEditAnswers: 'Edytuj swoje odpowiedzi',
  questionnaireLetsGo: 'Let\'s go!',
  questionnaireYes: 'Tak',
  questionnaireNo: 'Nie',

  mainInfoBlock1Title1: "FaceTime - to oznacza",
  mainInfoBlock1Title2: "wygodę, troskę i miłość.",
  mainInfoBlock1Text: "Nasze wnętrze jest pełne słońca i ciepła; zaprojektowaliśmy je specjalnie, aby zapewnić atmosferę w warszawskiej jesieni i zimie, gdzie można cieszyć się nie tylko wysokiej jakości kosmetologią i estetyką, ale także ciepłem i światłem słonecznym, którego czasami brakuje.",
  
  mainInfoBlock2Title1: "MARKA KOSMETYCZNA,",
  mainInfoBlock2Title2: "KTÓREJ UFASZ",
  mainInfoBlock2Text: "Nasze spotkanie z kosmetologiem zawsze zaczyna się od diagnostyki skóry i opracowania planu pielęgnacyjnego. Uwielbiamy sprawdzone produkty, dlatego opracowaliśmy procedury we współpracy z profesjonalną i sprawdzoną marką Germaine de Capuccini.",
  
  mainInfoBlock3Title1: "FORMAT",
  mainInfoBlock3Title2: "STAŁYCH CEN",
  mainInfoBlock3Text: "Stworzyliśmy zabiegi, które trwają dokładnie godzinę, a na większe rytuały - trochę dłużej. Wszystkie zabiegi ma stałą cenę i nie ulega zmian.",

  mainInfoTitle: "O NAS",
  mainInfoSubtitle: "FaceTimeCosmetology - pierwsza kosmetologia, oparta na diagnostyce aparaturowej.",
  mainInfoText: "Zależy nam, aby Twoja skóra była idealna  po każdym zabiegu. Dlatego pracujemy kompleksowo, uwzględniając wszystkie zmiany w Twojej skórze, dobierając pielęgnację domową i monitorując za pomocą aparatury. Twoje zaufanie jest dla nas bardzo ważne, dlatego używamy tylko skutecznych procedur opartych na dowodach medycznych.",
  
  profileYourRecords: 'Twoje wizyty:',
  profileNoRecords: 'Brak wpisów',
  profileYouDontHaveRecords: 'Nie masz wpisów',
  profileConfirmations: 'Potwierdzenia',
  profileConfirmation: 'Potwierdzenie',
  profileRecordDelete: 'Usuwać',
  profileRecordCancel: 'Anulować',
  profileConfirmationTitle: 'Jesteś pewny?',
  profileDeleteRecordTitle: 'Czy na pewno chcesz usunąć rekord?',

  orderDialogInitTitle: 'Zarezerwuj wizytę',
  orderDialogServicesTitle: 'Lista usług',
  orderDialogMastersTitle: 'Lista kosmetologów',
  orderDialogTimesTitle: 'Wybierz czas',
  orderDialogSumarryTitle: 'Wybrane procedury',
  orderDialogFinalTitle: 'Gratulacje!',
  orderDialogLoginTitle: 'Upoważnienie',

  orderDialogServicesNextText: 'Wybierz usługę',
  orderDialogMastersNextText: 'Wybierz kosmetologa',
  orderDialogTimesNextText: 'Wybierz czas',
  orderDialogSumarryNextText: 'Dodaj usługę',
  orderDialogFinalNextText: 'Zarezerwuj wybrane usługi',

  orderDialogTimeMorning: 'Rano',
  orderDialogTimeAfternoon: 'Popołudnie',
  orderDialogTimeEvening: 'Wieczór',
  orderDialogTimeNight: 'Noc',
  
  orderDialogTimeNoItems: 'W tym dniu nie ma wolnych terminów',

  profileNoteCreate: 'Zrobić notatkę',
  profileNoteEdit: 'Edytować',
  profileNoteEmpty: '<Pusty>',
  profileNoteYour: 'Twoje notatki:',
  
  save: 'Zapisać',
  saved: 'Zapisano',
  edit: 'Edytować',
  cancel: 'Anulować',
  send: 'Wysłać',
  text: 'Tekst',
  email: 'E-mail',
  name: 'Imię',
  sending: 'Wysyłanie...',
  feedbackWasAdded: 'Opinia została dodana!',
  yes: 'Tak',
  no: 'Nie',
  change: 'Zmiana',
  delete: 'Usuwać',
  accept: 'Potwierdzać',
  reset: 'Przeskładać',
  check: 'Sprawdzać',
  
  footerWorkTimeTitle: 'Godziny pracy',
  footerWorkTime: 'Codziennie od 9:00 do 21:00',
  footerContactsTitle: 'Kontakty',
  footerNetworksTitle: 'Jesteśmy w sieciach społecznościowych',
  
  additionalRecomendations: 'Zalecenia po zabiegu',
  profileMasterSchedule: 'Grafik kosmetologa',
  profileQuestionnaire: 'Ankieta',
  profileQuestionnaireShow: 'Pogląd',
  profileEditTitle: 'Edytuj profil',
  profileFirstName: 'Imię',
  profileLastName: 'Nazwisko',
  profilePhone: 'Telefon',

  giftCardsTitle1: 'Możesz zakupić bon podarunkowy na dowolną kwotę (od 250 zł) lub usługę.',
  giftCardsTitle2: 'Bon może być personalizowany, bez podawania jego wartości',
  giftCardsTitle3: '(wszystkie szczegóły zostaną zarejestrowane osobno w naszej bazie danych).',
  
  
  advicesItem1Title: 'Jak dbać o skórę twarzy wiosną i latem?',
  advicesItem2Title: 'Jakie są korzyści z peelingu enzymatycznego?',
  advicesItem3Title: 'Jak właściwie wybrać krem z filtrem SPF?',
  advicesItem4Title: 'Jak określić typ skóry za pomocą papierowej chusteczki?',
  advicesItem5Title: 'Etapy podstawowej pielęgnacji skóry',
  advicesItem6Title: 'Pielęgnacja skóry wokół oczu',
  
  advice1Text: `1. Dla suchej, tłustej i mieszanej skóry konieczne jest oczyszczanie rano i wieczorem oraz stosowanie profesjonalnych produktów dopasowanych przez Twojego kosmetologa.
  
  2. W przypadku skóry problematycznej należy używać kosmetyków o aktywnym składzie i działaniu regenerującym.
  
  3. Zastąpienie podkładu kremem BB/CC przyniesie korzyści i nie będzie zapychać porów.
  
  4. Stosowanie kremu z filtrem SPF50 przed promieniowaniem UVB i UVA.
  
  5. Nawilżający krem powinien mieć lekką konsystencję i szybko się wchłaniać.
  `,
  advice2Text: `Peeling enzymatyczny to rodzaj delikatnego złuszczania, podczas którego enzymy pochodzenia roślinnego rozkładają warstwę zrogowaciałą skóry (jest to warstwa martwych komórek, która utrudnia aktywne działanie wszystkich produktów do pielęgnacji domowej). Regularne stosowanie enzymatycznego peelingu przyspiesza odnowę naskórka, co oznacza, że pomaga naszej skórze zachować młodość. Po peelingowaniu idealnym rozwiązaniem jest stosowanie masek na twarz. W ten sposób faktycznie wnikną one w skórę i zadziałają z maksymalnym efektem. Maseczki powinny być stosowane jako dodatkowa pielęgnacja 1-2 razy w tygodniu. Wszystko zależy od potrzeb Twojej skóry, podstawą jest: oczyszczanie + nawilżanie i odżywianie.
  `,
  advice3Text: `Przy wyborze kremu z filtrem SPF ważne jest uwzględnienie stopnia ochrony, a mianowicie:

  1. SPF poniżej 15 pochłania 50–75% promieniowania UV;
  2. SPF 15–20 zapewnia wysoki poziom ochrony – 95%;
  3. SPF 30–50 krem chroni przed 97% promieniowaniem UV;
  4. SPF 50+ to najwyższy poziom ochrony przed promieniowaniem UV – 98%.

  !!!Krem z filtrem SPF należy odświeżać co 2–3 godziny i po kontakcie z wodą. Promieniowanie UV działa nawet w pochmurne dni, dlatego krem z filtrem przeciwsłonecznym należy stosować nie tylko latem, ale przez cały rok.
  `,
  advice4Text: `1. Umyj twarz rano swoim zwyczajowym sposobem, używając również toniku. Nie nakładaj kremu na twarz.
  2. Po 2 godzinach weź zwykłą suchą papierową chusteczkę i delikatnie przetrzyj twarz.

  Sprawdź wyniki:

  - sucha skóra – jeśli nie ma na papierze żadnych śladów;
  - mieszana – plamy na czole, brodzie, policzkach i nosie;
  - normalna – jeśli są lekkie plamy na całej powierzchni chusteczki;
  - tłusta skóra – intensywne plamy na całej powierzchni chusteczki.

  ! Ważne: Domowa pielęgnacja to 90% Twojego sukcesu. Żaden kosmetolog nie pomoże Ci, jeśli w domu będziesz naruszać barierę ochronną skóry, nadmiernie ją wysuszysz, będziesz używać niskiej jakości lub nieodpowiednich produktów do pielęgnacji skóry itp.
  `,
  advice5Text: `1. Oczyszczanie – jedna z najważniejszych faz, którą często pomijamy lub popełniamy poważne błędy. Aby nasze kremy i serum działały skutecznie, nie powinny być hamowane przez warstwę brudu i kurzu! Skórę należy oczyszczać 2 razy dziennie - rano i wieczorem. Do oczyszczania koniecznie używaj produktów odpowiednich dla Twojego typu skóry.
  2. Tonizowanie przywraca skórze pH, które zostało zakłócone podczas mycia, oraz wykonuje dodatkowe funkcje, zależnie od wyboru toniku. Może łagodzić lub zwężać pory, balansować skórę mieszana. Tonizację przeprowadzamy 2 razy dziennie, po umyciu twarzy, na suchą skórę.
  3. Końcowe produkty – można je nakładać na skórę nawet jeśli tonik jeszcze nie wyschnął, wtedy działa jako nośnik. Lepiej wybrać profesjonalną linię końcowych produktów, ponieważ to naprawdę zaoszczędzi Twoje pieniądze. Ponadto profesjonalne linie są znacznie bardziej skoncentrowane w składzie, naprawdę działają.
  
  ! Ważne: Serum zawsze należy zamykać kremem! Zawsze. To nie jest samodzielny produkt (wyjątkiem jest sytuacja, gdy producent wyraźnie to określi).
  `,
  advice6Text: `Skóra wokół oczu jest cieńsza i delikatniejsza i oczywiście wymaga dodatkowej pielęgnacji. Również wymaga oczyszczania i tonizowania. Zalecamy stosowanie sprayu, zazwyczaj jest to wygodniejsze. Po prostu wlej swój ulubiony tonik do odpowiedniego pojemnika ze spryskiwaczem. Następnie stosuj specjalnie dobrany krem do oczu.
  `,
  
  feedbackDialogCreate: 'Wystawić opinię',
  feedbackAnswered: 'Wyjmij z nowego',
  feedbackUnmarkAnswered: 'Dodaj do nowego',
  feedbackIsNew: 'Nowe opinie',
  feedbackCreatedAt: 'Utworzony',
  feedbackRemoveFromNew: 'Czy chcesz usunąć tę opinię z sekcji Nowe opinie?',
  feedbackRemoveFromAll: 'Chcesz przejść do nowych recenzji?',
  feedbacks: 'Opinie',
  newFeedbacks: 'Nowe opinie',
  allFeedbacks: 'Wszystkie opinie',
  showAllFeedbacks: 'Pokaż wszystko',
  noNewFeedbacks: 'Żadnych nowych opinii',
  
  workingTime: 'Czas pracy',
  currentTime: 'Obecny czas',
  addService: 'Dodaj usługę',
  hasQuestions: 'Nadal masz pytania?',
  haveACall: 'Dzwonić',
  removeMaster: 'Usuń mistrza',
  areYouSureToDeleteMaster: 'Czy na pewno chcesz usunąć kosmetyczkę?',
  confirmation: 'Potwierdzenie',
  masterWasRemoved: 'Kosmetolog pomyślnie usunięty',
  addWorkingTime: 'Dodaj godziny pracy',
  cosmetologist: 'Kosmetolog',
  add: 'Dodać',
  done: 'Zrobione',
  timeWasAdded: 'Czas został pomyślnie dodany!',
  startLabel: 'czas rozpoczęcia',
  endLabel: 'czas końcowy',

  source: "Pochodzi z",
  SourcePlatformUnknown: "Pusty",
  SourcePlatformInst: "Instagram",
  SourcePlatformGoogle: "Google",
  SourcePlatformOther: "Coś innego",

  authorize: "Upoważnienie",
  authRestorePassword: "Przywróć hasło",
  authSignIn: "Zalogować się",
  authSignUp: "Zapisać się",
  authWithGoogle: "Kontynuuj z Google",
  authLogin: "Login",
  authFormEmail: "Adres e-mail",
  authFormPassword: "Hasło",
  authFormRepeatPassword: "Powtórz hasło",
  authRestorePassword: "Przywróć hasło",
  authRegistration: "Rejestr",
  authRegister: "Register",
  or: "albo",
  authBackToLogin: "Powrót do logowania",
  authRestore: "Przywrócić",
  authRestoreEmailSent: "Na Twój adres e-mail została wysłana wiadomość pomocnicza.",
  accountInfoCode: "Wpisz kod",
  invitePageEnterCodeTitle: "Wprowadź kod wejściowy",
  codeForClientsAgreement: "Kod umożliwiający wyrażenie zgody na warunki",
  codeForClientsAgreementMessage: "Kod",
  inviteCodePageYourRecord: "Twój wpis",
  inviteCodeDialogClientAccepted: "Klient już zaakceptował umowę.",
  areYouSure: "Jesteś pewien?",
  code: "Kod",
  codeNotFound: "Ten kod nie został znaleziony, spróbuj ponownie z innym kodem",
  selectUser: "Wybierz użytkownika",
  timeIsNotAvailable: "Wybrany termin jest już zajęty. Proszę spróbować wybrać inny termin.",
  loginToBookRecord: "Proszę zalogować się na swoje konto, aby zakończyć rezerwację.",
  newTimeForRecord: "Nowy czas na rekord",
  newTimeMoveButton: "Przenosić",
  controlPanelUsers: "Użytkownicy",
  controlPanelUsersCount: "Użytkowników: ",
  filter: "Filtr",
  search: "Szukanie",
  filterRealUsersAll: "Wszyscy",
  filterRealUsersReal: "Prawdziwi",
  filterRealUsersFake: "Fałszywi",
  codeConfirmationAccepted: "Pomyślnie potwierdzono!",
  codeConfirmationDeleted: "Rekord został usunięty!",
  codeConfirmationTitle: "Potwierdzenie rekordu",
  codeConfirmationNoRecord: "Rekord nie został znaleziony",
  mainReviews: "Recenzje",
  freeTimeOfMaster: "Czas wolny",
  allMasters: "Wszystko",
}

export default pl;