const ru = {
  topMenuButtonName: 'Меню',
  topMenuMain: 'Главная',
  topMenuServices: 'Процедуры',
  topMenuFirstTime: 'В первый раз',
  topMenuForWoman: 'Для женщин',
  topMenuForMan: 'Для мужчин',
  topMenuForBody: 'Для тела',
  topMenuConsultations: 'Консультации',
  topMenuSubscriptions: 'Абонементы',
  topMenuRecords: 'Запись',
  topMenuContacts: 'Контакты',
  topMenuGiftCards: 'Подарочные сертификаты',
  topMenuBookButtonText: 'Записаться',
  topMenuAdvices: 'Советы косметолога', 

  serviceItemTime: 'ВРЕМЯ',
  serviceItemTimeMin: 'мин',
  serviceItemPrice: 'ЦЕНА',
  
  bookButtonText: 'Записаться',
  serviceRecommendations: 'Показания',
  serviceTime: 'Время процедуры',

  risksExtraInformation: 'Дополнительная информация',
  risksRecommendations: 'Показания',
  risksRisks: 'Противопоказания',

  timeMin: 'мин',

  accountInfoProfile: 'Профиль',
  accountInfoLogin: 'Войти',
  accountInfoLogout: 'Выйти',
  accountInfoRegister: 'Регистрация',

  bookDialogNoEmptySlots: 'Нет свободных окошек',
  bookDialogNoMastersFound: 'не найдены косметологи для этой услуги ',
  bookDialogContinue: 'Продолжить',
  bookDialogTotal: 'Всего',
  bookDialogCongrats: 'Поздравляем!',

  bookDialogFinalYouHaveRecordNext: 'Ваша ближайшая запись',
  bookDialogFinalYouHaveRecord: 'Вы записаны на',
  bookDialogFinalMaster: 'Косметолог',

  questionnaireShare: 'Пожалуйста, заполните анкету',
  questionnaireNewQuestions: 'Пожалуйста, ответьте на новые вопросы анкеты',
  questionnaireEditAnswers: 'Редактировать анкету',
  questionnaireLetsGo: 'Вперед!',
  questionnaireYes: 'Да',
  questionnaireNo: 'Нет',
  
  mainInfoBlock1Title1: 'FaceTime - это про',
  mainInfoBlock1Title2: 'комфорт, заботу и любовь.',
  mainInfoBlock1Text: 'В нашем интерьере очень много солнца и тепла, мы сделали это специально, чтобы в осенней и зимней Варшаве было место, где можно получить удовольствие не только от качественной косметологии и эстетики, но и от тепла и солнца, которого иногда так не хватает.',
  
  mainInfoBlock2Title1: 'БРЕНД КОСМЕТИКИ,',
  mainInfoBlock2Title2: 'КОТОРОМУ ДОВЕРЯЕШЬ',
  mainInfoBlock2Text: 'Нашу встречу косметолог всегда начнёт с диагностики кожи и составит план ухода. Мы любим проверенные средства, поэтому разработали процедуры в сотрудничестве с профессиональным и проверенным брендом Germaine de Capuccini',
  
  mainInfoBlock3Title1: 'ФОРМАТ',
  mainInfoBlock3Title2: 'фиксированных цен',
  mainInfoBlock3Text: 'Мы создали уходы, на которые нужно ровно час, а для больших событий - чуть дольше. На всё - фиксированная стоимость  и никаких сюрпризов.',
  
  mainInfoTitle: 'О НАС',
  mainInfoSubtitle: 'FaceTimeCosmetology - первая косметология, в основе которой лежит аппаратная диагностика.',
  mainInfoText: 'Нам важно, чтобы ваша кожа была идеальна не только после процедуры, но и в динамике. Поэтому мы работаем комплексно, учитывая все изменения Вашей кожи, корректируя домашний уход и мониторя с помощью аппаратов. Ваше доверие очень важно для нас, поэтому мы используем в работе только рабочие процедуры, основанные на доказательной медицине.',
  
  profileYourRecords: 'Ваши записи:',
  profileNoRecords: 'Записей нет',
  profileYouDontHaveRecords: 'У Вас нет записей',
  profileConfirmations: 'Подтверждения',
  profileConfirmation: 'Подтверждение',
  profileRecordDelete: 'Удалить',
  profileRecordCancel: 'Отмена',
  profileConfirmationTitle: 'Вы уверены?',
  profileDeleteRecordTitle: 'Вы уверены, что хотите удалить запись?',

  orderDialogInitTitle: 'Записаться',
  orderDialogServicesTitle: 'Список услуг',
  orderDialogMastersTitle: 'Список косметологов',
  orderDialogTimesTitle: 'Выбор времени',
  orderDialogSumarryTitle: 'Выбранные процедуры',
  orderDialogFinalTitle: 'Поздравляем!',
  orderDialogLoginTitle: 'Авторизация',

  orderDialogServicesNextText: 'Выбрать услугу',
  orderDialogMastersNextText: 'Выбрать косметолога',
  orderDialogTimesNextText: 'Выбрать время',
  orderDialogSumarryNextText: 'Добавить услугу',
  orderDialogFinalNextText: 'Записаться на выбранные услуги',
  
  orderDialogTimeMorning: 'Утро',
  orderDialogTimeAfternoon: 'День',
  orderDialogTimeEvening: 'Вечер',
  orderDialogTimeNight: 'Ночь',

  orderDialogTimeNoItems: 'В этот день нет свободного времени',

  profileNoteCreate: 'Сделать пометку',
  profileNoteEdit: 'Редактировать',
  profileNoteEmpty: '<Пусто>',
  profileNoteYour: 'Ваши пометки:',

  save: 'Сохранить',
  saved: 'Сохранено',
  edit: 'Редактировать',
  cancel: 'Отмена',
  send: 'Отправить',
  text: 'Текст',
  email: 'E-mail',
  name: 'Имя',
  sending: 'Отправка...',
  feedbackWasAdded: 'Отзыв добавлен!',
  yes: 'Да',
  no: 'Нет',
  change: 'Изменить',
  delete: 'Удалить',
  accept: 'Подтвердить',
  reset: 'Сбросить',
  check: 'Проверить',
  
  footerWorkTimeTitle: 'Время работы',
  footerWorkTime: 'Ежедневно с 9:00 до 21:00',
  footerContactsTitle: 'Контакты',
  footerNetworksTitle: 'Мы в социальных сетях',

  additionalRecomendations: 'Рекомендации после процедуры',
  profileMasterSchedule: 'Расписание косметолога',
  profileQuestionnaire: 'Анкета',
  profileQuestionnaireShow: 'Просмотр',
  profileEditTitle: 'Редактировать профиль',
  profileFirstName: 'Имя',
  profileLastName: 'Фамилия',
  profilePhone: 'Телефон',

  giftCardsTitle1: 'Вы можете приобрести сертификат на любую сумму (от 250 zl) или услугу,',
  giftCardsTitle2: 'сертификат может быть именным, без указания его стоимости',
  giftCardsTitle3: '(все будет зафиксировано отдельно в нашей базе).',


  advicesItem1Title: 'Как ухаживать за кожей лица в весенне-летний период?',
  advicesItem2Title: 'Энзимный пилинг в чём польза?',
  advicesItem3Title: 'Как правильно выбрать крем с SPF?',
  advicesItem4Title: 'Как определить тип кожи с помощью бумажной салфетки?',
  advicesItem5Title: 'Этапы основного ухода за кожей',
  advicesItem6Title: 'Уход за кожей вокруг глаз',

  advice1Text: `1.	Для сухой, жирной и комбинированной кожи обязательны умывания утром и вечером, использование профессиональных средств, подобранных Вашим косметологом.\n
  2.	При проблемном типе коже необходимо применять косметику с активным составом и восстанавливающим эффектом. \n
  3.	Замена тонального средства на BB/CC крем принесет пользу и не будет забивать поры.\n
  4.	Использование солнцезащитного крема SPF50 от UVB и UVA излучений.\n
  5.	Увлажняющий крем должен иметь легкую текстуру, быстро впитываться.\n
  `,
  advice2Text: `Энзимный пилинг - это вид мягкого пилинга, при котором энзимы растительного происхожения расщепляют ороговевший слой кожи (по сути, это слой мертвых клеток, который мешает активно работать всем средствам Вашего домашнего ухода). Регулярное использование энзимного пилинга ускоряет обновление эпидермиса, а значит – помогает нашей коже сохранять молодость. После энзимного пилинга идеально использование масок для лица. Так они действительно проникнут в кожу и поработают с максимальным результатом. Маски должны быть в дополнительном уходе 1-2 раза в неделю. Здесь уже все зависит от потребностей Вашей кожи, база: очищающая + увлажнение и питание.
  `,
  advice3Text: `При выборе крема с SPF важно! учитывать степень его защиты, а именно:\n
    1.	SPF ниже 15 поглощает 50–75% ультрафиолета;
    2.	SPF 15–20 высокая степень защиты – 95%;
    3.	SPF 30–50 крем защищает от 97% УФ;
    4.	SPF 50+ самый высокий уровень защиты от ультрафиолета – 98%.

    !!!Обновлять крем с SPF необходимо каждые 2–3 часа и после контакта с водой. Ультрафиолет оказывает воздействие на коду даже в пасмурную погоду, поэтому использовать солнцезащитный крем нужно не только летом, а также в течение всего года.
  `,
  advice4Text: `1.	Умыться утром привычным способом, тоник используем также. Не наносим крем на лицо.
    2.	Через 2 часа берем обычную сухую бумажную салфетку и промакиваем лицо.\n
    Смотрим результаты:\n
    - сухая кожа – если у Вас совсем не осталось следов на бумаге;
    - комбинированная – пятна на лбу, подбородке, щеках и носу;
    - нормальная – если есть легкие пятна по всей области салфетки;
    - жирная кожа – интенсивные пятна по всей области салфетки.

    !Важно Домашний уход – это 90% Вашего результата. Никакой косметолог Вас не спасет, если дома Вы будете нарушать защитную мантию кожи, пересушивать ее, использовать некачественные или неподходящие средства по уходу за кожей и т.д.
  `,
  advice5Text: `1.	Очищение – один из важнейших этапов, которым часто пренебрегают или допускают серьезные ошибки. Ведь для того, чтобы наши крема и сыворотки работали эффективно, им не должен мешать слой грязи и пыли! Кожу необходимо очищать 2 раза в день - утром и вечером. Для очищения обязательно использовать средства, подходящие именно для Вашего типа кожи.
    2.	Тонизация возвращает коже PH, который нарушило до него умывание, плюс выполняет еще дополнительные функции, исходя из выбора тоника. Может успокаивать или сужать поры, балансировать комбинированный тип кожи. Тонизацию мы проводим 2 раза в день, после умывания, на сухую кожу лица. 
    3.	Завершающие средства – можно наносить на кожу, даже если тоник пока не высох, в этом случае он выступает проводником. Выбор завершающих средств лучше остановить на профессиональной линейке, т.к. это на самом деле сэкономит Ваши деньги. Плюс профессиональные линейки действительно намного насыщеннее посоставам, они реально работают. 

    !Важно Сыворотка всегда закрывается кремом! Всегда. Это не самостоятельный продукт (исключение, если этот момент указал производитель).
  `,
  advice6Text: `Кожа вокруг глаз более тонкая и нежная и, конечно же, требует дополнительного ухода. Ей также необходимо очищение, тонизация. Рекомендуем использовать спрей, как правило, так комфортнее. Просто залейте свой любимый тоник в подходящую упаковку со спреем. Затем специально подобранный крем для глаз, исходя из потребностей Вашей кожи. Обращаем Ваше внимание, что если крем имеет плотную текстуру он может вызывать отеки. Избежать этого можно в том случае, если Вы будете наносить крем как минимум за час до ухода ко сну. Это только в том случае, если отечность вызвана именно плотностью текстуры, а не аллергической реакцией или какими- либо проблемами со здоровьем. Обязательный дополнительный уход – это рутина ухода, ее стоит пропускать и/или менять только если Вы сейчас ходите к косметологу и Вы обсудили с ним этот момент, исходя из проводимых процедур. Мы рассмотрим вариант, где Вы на данный момент сами делаете дополнительный уход.
  `,
  
  feedbackDialogCreate: 'Оставить обратную связь',
  feedbackAnswered: 'Убрать из новых',
  feedbackUnmarkAnswered: 'Добавить к новым',
  feedbackIsNew: 'Новый отзыв',
  feedbackCreatedAt: 'Создан',
  feedbackRemoveFromNew: 'Хотите удалить этот отзыв из раздела «Новые отзывы»?',
  feedbackRemoveFromAll: 'Хотите перенести к новым отзывам?',
  feedbacks: 'Отзывы',
  newFeedbacks: 'Новые отзывы',
  allFeedbacks: 'Все отзывы',
  showAllFeedbacks: 'Показывать все',
  noNewFeedbacks: 'Нет новых отзывов',
  
  workingTime: 'Рабочее время',
  currentTime: 'Текущее время',
  addService: 'Добавить услугу',
  hasQuestions: 'Остались вопросы?',
  haveACall: 'Позвонить',
  removeMaster: 'Удалить мастера',
  areYouSureToDeleteMaster: 'Вы действительно хотите удалить косметолога?',
  confirmation: 'Подтверждение',
  masterWasRemoved: 'Косметолог успешно удален',
  addWorkingTime: 'Добавить рабочее время',
  cosmetologist: 'Косметолог',
  add: 'Добавить',
  done: 'Готово',
  timeWasAdded: 'Время было успешно добавлено!',
  startLabel: 'начало',
  endLabel: 'конец',

  source: "Пришел из",
  SourcePlatformUnknown: "Пусто",
  SourcePlatformInst: "Instagram",
  SourcePlatformGoogle: "Google",
  SourcePlatformOther: "Что-то другое",

  authorize: "Авторизация",
  authRestorePassword: "Восстановить пароль",
  authSignIn: "Войти",
  authSignUp: "Зарегистрироваться",
  authWithGoogle: "Продолжить с Google",
  authLogin: "Авторизация",
  authFormEmail: "E-mail адрес",
  authFormPassword: "Пароль",
  authFormRepeatPassword: "Повторите пароль",
  authRestorePassword: "Восстановить пароль",
  authRegistration: "Регистрация",
  authRegister: "Зарегистрироваться",
  or: "или",
  authBackToLogin: "Вернуться к входу",
  authRestore: "Восстановить",
  authRestoreEmailSent: "Письмо для восстановления было отправлено на ваш email",
  accountInfoCode: "Ввести код",
  invitePageEnterCodeTitle: "Введите код записи",
  codeForClientsAgreement: "Код для согласия с условиями",
  codeForClientsAgreementMessage: "Код",
  inviteCodePageYourRecord: "Ваша запись",
  inviteCodeDialogClientAccepted: "Клиент уже принял соглашение.",
  areYouSure: "Вы уверены?",
  code: "Код",
  codeNotFound: "Этот код не найден, попробуйте еще раз с другим кодом",
  selectUser: "Выберите пользователя",
  timeIsNotAvailable: "Выбранное время уже занято. Пожалуйста, попробуйте выбрать другое время.",
  loginToBookRecord: "Пожалуйста, войдите в свой аккаунт, чтобы завершить запись.",
  newTimeForRecord: "Новое время для записи",
  newTimeMoveButton: "Переместить",
  controlPanelUsers: "Пользователи",
  controlPanelUsersCount: "Пользователей: ",
  filter: "Фильтр",
  search: "Поиск",
  filterRealUsersAll: "Все",
  filterRealUsersReal: "Реальные",
  filterRealUsersFake: "Фейковые",
  codeConfirmationAccepted: "Успешно подтверждено!",
  codeConfirmationDeleted: "Запись удалена!",
  codeConfirmationTitle: "Подтверждение записи",
  codeConfirmationNoRecord: "Запись не найдена",
  mainReviews: "Отзывы",
  freeTimeOfMaster: "Свободное время",
  allMasters: "Все",
}

export default ru;