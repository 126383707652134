import React, { useState } from "react";
import styles from "./AddNoteDialog.module.css";
import { Button, Dialog, DialogContent, DialogTitle, IconButton, TextField, styled } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const BlurryDialog = styled(Dialog)(({ theme }) => ({
  backdropFilter: "blur(5px)"
}));

const AddNoteDialog = ({ open, onClose, note, onChange }) => {

  const handleClose = () => {
    onClose();
  }

  return (
    <>
      <BlurryDialog onClose={handleClose} open={open} fullWidth={true} maxWidth="sm">
        <DialogTitle sx={{ m: 0, paddingRight:7 }} id="customized-dialog-title">
          Редактировать
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 12,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent >
          <div className={styles.box}>
            <TextField value={note ?? ""} onChange={event => onChange(event.target.value)} label="Пометка" fullWidth/>
            <Button variant="contained" onClick={handleClose}>Сохранить</Button>
          </div>
        </DialogContent>
      </BlurryDialog>
    </>
  );
};

export default AddNoteDialog;