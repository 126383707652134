
import styles from './ReviewCards.module.css';
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import "swiper/swiper-bundle.css";
import ReviewCard from '../ReviewCard';
import QUERY_KEYS from '../../../net/query-keys';
import REQUESTS from '../../../net/requests';
import { CircularProgress, Typography, useMediaQuery } from '@mui/material';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';

const ReviewCards = () => {
  
  const { t } = useTranslation();
  const maxW500 = useMediaQuery('(max-width:500px)');
  const reviewsRequest = useQuery(QUERY_KEYS.CLIENTS_REVIEWS, REQUESTS.getClientsReviews())

  if (reviewsRequest.isLoading) {
    return <CircularProgress />
  }
  if (reviewsRequest.isError) {
    return <Typography variant="h6">Can't load reviews</Typography>
  }

  const reviews = reviewsRequest.data;

  return (
    // <div className={styles.sliderBox}>
    //   <Swiper 
    //     modules={[Pagination]}
    //     spaceBetween={52} 
    //     slidesPerView="auto" 
    //     grabCursor={true}
    //     freeMode={true}
    //     loop={false}
    //     navigation={false}
    //     pagination={{ clickable: true }}
    //   >
        // { 
        //   reviews.map((review, index) => {
        //     return <SwiperSlide style={{ width: maxW500 ? "300px" : "400px", height: "580px", paddingLeft: "24px", paddingRight: "24px" }}>
        //       <ReviewCard url={review.profile_photo_url} name={review.author_name} text={review.text} />
        //     </SwiperSlide>
        //   }) 
        // }
        
    //   </Swiper>
    // </div>
    <div className={styles.box}>
      <Typography variant="h4" sx={{ mb: 4 }} align="center">{ t('mainReviews') }</Typography>
      <div className={styles.reviews}>
        { 
          reviews.map((review, index) => {
            return <SwiperSlide key={review.time} style={{ width: maxW500 ? "300px" : "400px", height: "580px", paddingLeft: "24px", paddingRight: "24px" }}>
              <ReviewCard url={review.profile_photo_url} name={review.author_name} text={review.text} />
            </SwiperSlide>
          }) 
        }
      </div>
    </div>
  );
};

export default ReviewCards;