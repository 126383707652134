import React, { useState } from "react";
import styles from "./FeaturedService.module.css";
import { Box, Button, TextField, Typography, useMediaQuery } from '@mui/material';
import AccountInfo from "../AccountInfo";
import TopMenu from "../TopMenu/TopMenu";
import CountrySelect from "../CountrySelect";
import Logo from "../../../svg/facetime_logo.svg"
import { Link } from "react-router-dom";
import URLS from "../../../constants/urls";
import { useSelector } from "react-redux";
import { getPrice, getTimeRequirements } from "../../dialogs/utils";
import BookButton from "../BookButton";
import BookDialog from "../../dialogs/BookDialog";
import { useHistory } from "react-router-dom";
import RisksDialog from "../../dialogs/RisksDialog";
import { useTranslation } from "react-i18next";
import OrderDialog from "../../dialogs/OrderDialog";
import BookButtonWhite from "../BookButtonWhite";

const FeaturedService = ({ service, from }) => {
  const user = useSelector(state => state.user);
  const lang = useSelector(state => state.lang).title.toLowerCase();

  const [showDialog, setShowDialog] = useState(false);
  const [showRisksDialog, setShowRisksDialog] = useState(false);
  const history = useHistory();
  const { t } = useTranslation();
  const maxW700 = useMediaQuery('(max-width:700px)');

  const title = service.titles.find(v => v.lang === lang)?.text ?? "";
  const subtitle = service.subtitles.find(v => v.lang === lang)?.text ?? "";
  const text = service.descriptions.find(v => v.lang === lang)?.text ?? "";
  const price = getPrice(service.minPrice, service.maxPrice, maxW700);

  const handleBookClicked = () => {
    setShowDialog(true);
    // if (user) {
    //   setShowDialog(true);
    // } else {
    //   history.push(URLS.LOGIN, { from: from ? from : URLS.ROOT_URL });
    // }
  }
  return (
    <>
      <div className={styles.box}>

        <div className={styles.title}>
          <div className={styles.serviceName}>
            <Typography variant="p" sx={{ fontSize: maxW700 ? 20 : 40, fontWeight: maxW700 ? 'bold' : 'normal' }}>{ title }</Typography>
          </div>
          
          <Typography variant="p" sx={{ fontSize: maxW700 ? 16 : 18 }}>{ price }</Typography>
        </div>

        <div className={styles.content}>

          <div className={styles.imageBox}>
            <img src={service.imageUrl} className={styles.image} />
          </div>

          <div className={styles.contentBox}>

            <Typography variant="h4" sx={{ fontSize: maxW700 ? 18 : 28, fontWeight: maxW700 ? 'bold' : 'normal' }}>{ subtitle }</Typography>
            <Box sx={{ paddingTop: subtitle ? 2 : 0, display: "flex", flexDirection: "column" }}>
              {
                text.split("\n").filter(v => v !== '').map(substring => <Typography key={substring} variant="p" sx={{ fontSize: 18 }}>{ substring }</Typography>)
              }
            </Box>

            <div className={styles.bookBox}>
              {/* <BookButton onClick={handleBookClicked}/> */}
              
              <BookButtonWhite onClick={handleBookClicked}/>

              <div className={styles.serviceTime}>
                <Typography variant="p" sx={{ fontSize: 16 }}>{ t('serviceTime') }</Typography>
                <Typography variant="h6" sx={{ fontSize: 16, fontWeight: "bold" }}>{ getTimeRequirements(service.timeRequirementsMin, service.timeRequirementsMax) } { t('timeMin') }</Typography>
              </div>

              {
                service.hasRisks && <div className={styles.recomendations}>
                  <Typography variant="p" sx={{ fontSize: 16 }} onClick={() => setShowRisksDialog(true)} >{ t('serviceRecommendations') }</Typography>
                  { showRisksDialog && <RisksDialog onClose={() => setShowRisksDialog(false)} open={showRisksDialog} serviceId={service.id} /> }
                </div>
              }
            </div>

          </div>
          
        </div>
        
        {/* { showDialog && <BookDialog service={service} onClose={() => setShowDialog(false)} open={showDialog} /> } */}
        { showDialog && <OrderDialog onClose={() => setShowDialog(false)} open={showDialog} targetService={service}/> }
      </div>
    </>
  );
};

export default FeaturedService;