import * as React from 'react';
import { Avatar, Button, Checkbox, CircularProgress, Dialog, DialogContent, DialogTitle, FormControlLabel, IconButton, TextField, Typography, styled } from "@mui/material";
import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import CloseIcon from '@mui/icons-material/Close';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styles from "./EditMasterDialog.module.css"
import TranslationTypes from '../../../constants/translationTypes';
import LANGS from '../../../constants/langs';
import REQUESTS from '../../../net/requests';
import QUERY_KEYS from '../../../net/query-keys';
import { logAndReset } from '../../../utils/requestUtils';

const BlurryDialog = styled(Dialog)(({ theme }) => ({
  backdropFilter: "blur(5px)"
}));

const EditMasterDialog = ({ open, onClose, master }) => {

  const findName = (lang, type) => {
    return master.translations.find(t => t.lang === lang.title.toLowerCase() && t.type === type)?.text;
  }

  const { t } = useTranslation();
  const [file, setFile] = React.useState()
  const [firstName, setFirstName] = React.useState(master.name ?? '');
  const [lastName, setLastName] = React.useState(master.surname ?? '');
  const [firstNameRu, setFirstNameRu] = React.useState(findName(LANGS.RU, TranslationTypes.MASTER_NAME) ?? '');
  const [lastNameRu, setLastNameRu] = React.useState(findName(LANGS.RU, TranslationTypes.MASTER_SURNAME) ?? '');
  const [firstNameEn, setFirstNameEn] = React.useState(findName(LANGS.EN, TranslationTypes.MASTER_NAME) ?? '');
  const [lastNameEn, setLastNameEn] = React.useState(findName(LANGS.EN, TranslationTypes.MASTER_SURNAME) ?? '');
  const [firstNamePl, setFirstNamePl] = React.useState(findName(LANGS.PL, TranslationTypes.MASTER_NAME) ?? '');
  const [lastNamePl, setLastNamePl] = React.useState(findName(LANGS.PL, TranslationTypes.MASTER_SURNAME) ?? '');
  const [isActive, setActive] = React.useState(master.isActive ?? false);
  
  const editMasterMutation = useMutation(REQUESTS.editMaster);
  const queryClient = useQueryClient();

  const handleClose = () => {
    onClose();
  }

  const handleSave = () => {
    const data = {
      id: master.id,
      name: firstName,
      surname: lastName,
      firstNameRu,
      lastNameRu,
      firstNameEn,
      lastNameEn,
      firstNamePl,
      lastNamePl,
      file,
      isActive
    }
    editMasterMutation.mutate(data, {
      onSuccess: (data, error, variables, context) => {
        queryClient.invalidateQueries(QUERY_KEYS.ALL_MASTERS_SCHEDULES);
        queryClient.invalidateQueries(QUERY_KEYS.MASTERS);
        
        setTimeout(() => {
          onClose();
        }, 1000)
      },
      onError: (error, variables, context) => {
        console.log(`onError:`, JSON.stringify(error))
        logAndReset(editMasterMutation);
      }
    });
  }

  const handleFileChange = (event) => {
    if (event.target.files) {
      console.log(event.target.files[0].name);
      setFile(event.target.files[0]);
    }
  }

  const handleChangeActive = (event) => {
    setActive(event.target.checked)
  }

  console.log('master:', master);

  return (
    <BlurryDialog onClose={handleClose} open={open}  maxWidth="sm">
      <DialogTitle sx={{ m: 0, paddingRight:7 }} id="customized-dialog-title">
        { t('edit') }
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 12,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent sx={{ paddingTop: 0}}>
        <div>
          <div className={styles.avatar}>
            <Avatar alt={master?.name} src={master?.imageUrl} sx={{ width: 112, height: 112 }} />
            <input accept="image/*" type="file" onChange={handleFileChange} />
          </div>

          <FormControlLabel
            label={ t("availableForRecords") }
            control={
              <Checkbox
                checked={isActive}
                onChange={handleChangeActive}
              />
            }
          />

          <Typography>Default</Typography>
          <div className={styles.langBox}>
            <TextField sx={{margin: 1}} label={ t('profileFirstName') } value={firstName} onChange={event => setFirstName(event.target.value)}></TextField>
            <TextField sx={{margin: 1}} label={ t('profileLastName') } value={lastName} onChange={event => setLastName(event.target.value)}></TextField>
          </div>

          <Typography sx={{mt: 2}}>Ru</Typography>
          <div className={styles.langBox}>
            <TextField sx={{margin: 1}} label={ t('profileFirstName') } value={firstNameRu} onChange={event => setFirstNameRu(event.target.value)}></TextField>
            <TextField sx={{margin: 1}} label={ t('profileLastName') } value={lastNameRu} onChange={event => setLastNameRu(event.target.value)}></TextField>
          </div>

          <Typography sx={{mt: 2}}>En</Typography>
          <div className={styles.langBox}>
            <TextField sx={{margin: 1}} label={ t('profileFirstName') } value={firstNameEn} onChange={event => setFirstNameEn(event.target.value)}></TextField>
            <TextField sx={{margin: 1}} label={ t('profileLastName') } value={lastNameEn} onChange={event => setLastNameEn(event.target.value)}></TextField>
          </div>

          <Typography sx={{mt: 2}}>Pl</Typography>
          <div className={styles.langBox}>
            <TextField sx={{margin: 1}} label={ t('profileFirstName') } value={firstNamePl} onChange={event => setFirstNamePl(event.target.value)}></TextField>
            <TextField sx={{margin: 1}} label={ t('profileLastName') } value={lastNamePl} onChange={event => setLastNamePl(event.target.value)}></TextField>
          </div>

          <Button variant='contained' sx={{mt: 2}} fullWidth onClick={handleSave}>{ t('save') }</Button>
        </div>
      </DialogContent>
    </BlurryDialog>
  );
};

export default EditMasterDialog;