import { createStyles, createTheme } from "@mui/material/styles";
import { common } from '@mui/material/colors';

export const bookButtonStyle = createStyles({
  button: {
    // main styles,
    primary: {
      color: "#DC3545"
    }
  }
});

const theme = createTheme({
  typography: {
    fontFamily: "Roboto, sans-serif",
    // fontFamily: "MyFont",
  },
  palette: {
    type: 'light',
    primary: {
      main: "#218cac",
      selected: "#218caca6",
    },
    secondary: {
      main: "#78c0e0",
    },
    bookButton: {
      main: "#ffffff",
      dark: "#eeeeee",  
      contrastText: "#000000"
    },
    reviewButton: {
      main: "#CFDDBD",
      dark: "#deedcb",  
      contrastText: "#000000"
    },
    feedbackButton: {
      main: "#F8F6DD",
      dark: "#eeeeee",  
      contrastText: "#F8F6DD"
    },
    success: {
      main: "#28A745",
    },
    error: {
      main: "#DC3545",
    },
    warning: {
      main: "#FFC107",
    },
    info: {
      main: "#F8F9FA",
    },
    onBlackSurface: {
      main: "#ffffff",
      footerText: "#F8F6DD"
    },
    text: {
      muted: "#6C757D",
      onSurface: "#000000",
      onBackground: "#000000"
    },
    background: {
      default: '#dddddd',
      paper: '#ffffff',
      white: '#ffffff'
    },
  },
});

export default theme
