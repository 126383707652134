import React, { useState } from "react";
import styles from "./SelectUserDialog.module.css";
import { Avatar, Box, Button, CircularProgress, Dialog, DialogContent, DialogTitle, IconButton, List, ListItem, ListItemButton, ListItemText, MenuItem, TextField, Typography, styled } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useQuery } from "react-query";
import QUERY_KEYS from "../../../net/query-keys";
import REQUESTS from "../../../net/requests";
import ServerError from "../../widgets/ServerError/ServerError";
import { userDisplayName } from "../../../utils/formatUtils";
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import URLS from "../../../constants/urls";
import { useTranslation } from "react-i18next";

const BlurryDialog = styled(Dialog)(({ theme }) => ({
  backdropFilter: "blur(5px)"
}));

const SelectUserDialog = ({ open, onClose, onChange }) => {

  const [filter, setFilter] = useState("")
  const usersRequest = useQuery(QUERY_KEYS.USERS, REQUESTS.getUsers, { refetchOnMount: true })
  const history = useHistory()
  const { t } = useTranslation()

  if (usersRequest.isLoading) {
    return <CircularProgress />
  }
  if (usersRequest.isError) {
    return <ServerError/>
  }

  const users = usersRequest.data 
  // const users = []; 
  // for (let i = 0; i < 50; i++) {
  //   users.push(users1[0])
  // }
  const names = {};
  users.forEach(user => {
    names[user.id] = userDisplayName(user, true)
  });

  const handleClose = () => {
    onClose(false);
  }

  const handleProfileClick = (user) => {
    history.push(URLS.profileOf(user.id));
    onClose(true);
  }

  const items = filter.length > 1 ? users.filter(v => names[v.id].toLowerCase().indexOf(filter.toLowerCase()) !== -1) : users;
  const allUsers = items.sort((a, b) => names[a.id].localeCompare(names[b.id]))
  return (
    <>
      <BlurryDialog onClose={handleClose} open={open} fullWidth={true} maxWidth="md">
        <DialogTitle sx={{ m: 0, paddingRight:7 }} id="customized-dialog-title">
          { t('selectUser') }
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 12,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent sx={{p:1}}>
          <Box sx={{pl:2, pr:2}}>
            <TextField value={filter} onChange={event => setFilter(event.target.value)} fullWidth/>
          </Box>
          
          <List>
            {
              allUsers.map(user => 
                <ListItem key={user.id} disablePadding>
                  <ListItemButton onClick={() => onChange(user)} >
                    <Avatar alt={user.displayName} src={user.avatar} sx={{ width: 36, height: 36 }} />
                    <ListItemText primary={ names[user.id] } sx={{ pl:2 }} />
                  </ListItemButton>
                  <IconButton onClick={() => handleProfileClick(user)}>
                    <AccountBoxIcon />
                  </IconButton>
                </ListItem>
              )
            }
          </List>
        </DialogContent>
      </BlurryDialog>
    </>
  );
};

export default SelectUserDialog;