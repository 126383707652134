import React from "react";
import { Button } from '@mui/material';
import { useTranslation } from "react-i18next";

const BookButtonWhite = ({ onClick, sx, titleKey, buttonColor }) => {
  const { t } = useTranslation()

  return <Button color={buttonColor ?? "bookButton"} variant="contained" sx={{ ...sx, borderRadius: 12, textTransform: 'none', whiteSpace: 'nowrap' }} onClick={onClick} >
    { t(titleKey ? titleKey : 'topMenuBookButtonText') }
  </Button>;
};

export default BookButtonWhite;