import { MenuItem, Select } from "@mui/material";
import React, { useState } from "react";

const MasterSelect = ({ masters, value, onChange }) => {
  const handleChange = (event) => {
    if (onChange) {
      onChange(event.target.value)
      console.log('qwe', event.target.value);
    }
  }

  return (
    <div>
      <Select value={value} onChange={handleChange} >
        {
          masters.map(item => <MenuItem key={item.id} value={item}>{ `${item.name} ${item.surname}` }</MenuItem>)
        }
      </Select>
    </div>
  );
}

export default MasterSelect;