import { CircularProgress, styled, Typography } from "@mui/material";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import ServerError from "../../components/widgets/ServerError/ServerError";
import ServiceListItem from "../../components/widgets/ServiceListItem";
import QUERY_KEYS from "../../net/query-keys";
import REQUESTS from "../../net/requests";
import styles from "./ProfilePage.module.css"
import { useParams } from 'react-router';
import { useSelector } from "react-redux";
import ROLES from "../../constants/roles";
import TimeFrameSetting from "../../components/widgets/TimeFrameSetting/TimeFrameSetting";
import AddMasterSchedules from "../../components/widgets/AddMasterSchedules";
import MasterSchedules from "../../components/widgets/MasterSchedules";
import ProfileInfo from "../../components/widgets/ProfileInfo";
import SchedulePanel from "../../components/widgets/SchedulePanel";
import ControlPanel from "../../components/widgets/ControlPanel";
import Users from "../../components/widgets/ControlPanelItems/Users";
import UserRecords from "./UserRecords";
import UserProfile from "./UserProfile";
import UnconfirmedRecords from "./UnconfirmedRecords";
import History from "./History";
import MasterSchedule from "./MasterSchedule";
import PageLayout from "../../components/layouts/PageLayout";
import ShowFeedbacks from "./ShowFeedbacks";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import EmptyMasterSchedule from "./EmptyMasterSchedule";

dayjs.extend(utc)
dayjs.extend(timezone)

const POLAND_TZ = "Europe/Warsaw"


const ProfilePage = () => {
  const user = useSelector((state) => state?.user);
  const { id } = useParams();
  
  console.log(`user: ${JSON.stringify(user)}, id: ${id}`)

  const userRequest = useQuery(QUERY_KEYS.getUserProfile(id), REQUESTS.getUserProfile(id))
  
  if (userRequest.isLoading) {
    return <CircularProgress />
  }
  if (userRequest.isError) {
    return <ServerError/>
  }

  const userData = userRequest.data
  console.log("userData: ", userData)
  console.log("dayjs: ", dayjs.utc().tz(POLAND_TZ).startOf('day').add(1, 'day').toDate())

  const isNotUser = user && userData.role !== ROLES.USER && userData.id === user.id;
  const isAdmin = user && userData.role === ROLES.ADMIN && userData.id === user.id;

  return (
    <PageLayout>
        <div className={styles.center}>
          <div className={styles.profile}>
            { 
              user && <>
                <UserProfile profile={userData} />
                <UserRecords />
              </>
            }
          </div>
        </div>

        { isNotUser && <SchedulePanel /> }

        { isNotUser && <EmptyMasterSchedule /> }

        { isNotUser && <MasterSchedule /> }

        { isNotUser && <UnconfirmedRecords profile={userData} /> }

        { isAdmin && <ShowFeedbacks /> }

        { user && user.role !== ROLES.USER && <History profile={userData}/> }

        { isNotUser && <Users /> }

        { isAdmin && <ControlPanel /> }

        {/* { user.role === ROLES.ADMIN && <TimeFrameSetting/>} */}
        
        {/* { user.role !== ROLES.USER && <MasterSchedules/>} */}
    </PageLayout>
  );
};

export default ProfilePage;
