const en = {
  topMenuButtonName: 'Menu',
  topMenuMain: 'Main',
  topMenuServices: 'Services',
  topMenuFirstTime: 'First time',
  topMenuForWoman: 'For woman',
  topMenuForMan: 'For man',
  topMenuForBody: 'For body',
  topMenuConsultations: 'Consultations',
  topMenuSubscriptions: 'Subscriptions',
  topMenuRecords: 'Order',
  topMenuContacts: 'Contacts',
  topMenuGiftCards: 'Gift Cards',
  topMenuBookButtonText: 'Book Now',
  topMenuAdvices: 'Cosmetologist\'s advice', 

  serviceItemTime: 'DURATION',
  serviceItemTimeMin: 'min',
  serviceItemPrice: 'PRICE',

  bookButtonText: 'Book Now',
  serviceRecommendations: 'Recommendations',
  serviceTime: 'Procedure time',

  risksExtraInformation: 'Extra information',
  risksRecommendations: 'Indications',
  risksRisks: 'Contraindications',

  timeMin: 'min',

  accountInfoProfile: 'Profile',
  accountInfoLogin: 'Log in',
  accountInfoLogout: 'Log out',
  accountInfoRegister: 'Register',

  bookDialogNoEmptySlots: 'All time slots are currently reserved for recording.',
  bookDialogNoMastersFound: 'no cosmetologists found for this service',
  bookDialogContinue: 'Continue',
  bookDialogTotal: 'Total',
  bookDialogCongrats: 'Congratulations!',

  bookDialogFinalYouHaveRecordNext: 'Your next appointment',
  bookDialogFinalYouHaveRecord: 'You have a record at',
  bookDialogFinalMaster: 'Cosmetologist',

  questionnaireShare: 'Please share your experience with us and answer a few questions',
  questionnaireNewQuestions: 'Give us answers to new questions',
  questionnaireEditAnswers: 'Edit your answers',
  questionnaireLetsGo: 'Let\'s go!',
  questionnaireYes: 'Yes',
  questionnaireNo: 'No',
  
  mainInfoBlock1Title1: "FaceTime - it's about",
  mainInfoBlock1Title2: "comfort, care, and love.",
  mainInfoBlock1Text: "Our interior is filled with lots of sunlight and warmth; we designed it specifically to provide a place in autumn and winter Warsaw where you can enjoy not only quality cosmetology and aesthetics but also the warmth and sunlight that are sometimes lacking.",
    
  mainInfoBlock2Title1: "COSMETIC BRAND",
  mainInfoBlock2Title2: "YOU TRUST",
  mainInfoBlock2Text: "Our meeting with a cosmetologist always begins with skin diagnostics and the creation of a care plan. We love proven products, so we have developed procedures in collaboration with the professional and trusted brand Germaine de Capuccini.",
  
  mainInfoBlock3Title1: "FIXED",
  mainInfoBlock3Title2: "PRICING FORMAT",
  mainInfoBlock3Text: "We have created treatments that take exactly an hour, and for larger events - a little longer. Everything comes with a fixed cost and no surprises.",
  
  mainInfoTitle: "ABOUT US",
  mainInfoSubtitle: "FaceTimeCosmetology - the first cosmetology based on hardware diagnostics.",
  mainInfoText: "It is important to us that your skin is perfect not only after the procedure but also in dynamics. That's why we work comprehensively, taking into account all changes in your skin, adjusting home care, and monitoring with the help of devices. Your trust is crucial to us, so we use only effective procedures based on evidence-based medicine in our work.",
  
  profileYourRecords: 'Your records:',
  profileNoRecords: 'No records here',
  profileYouDontHaveRecords: 'You have no records',
  profileConfirmations: 'Confirmations',
  profileConfirmation: 'Confirmation',
  profileRecordDelete: 'Delete',
  profileRecordCancel: 'Cancel',
  profileConfirmationTitle: 'Are you sure?',
  profileDeleteRecordTitle: 'Are you sure you want to delete the record?',
  
  orderDialogInitTitle: 'Book Now',
  orderDialogServicesTitle: 'List of Services',
  orderDialogMastersTitle: 'List of cosmetologists',
  orderDialogTimesTitle: 'Select Time',
  orderDialogSumarryTitle: 'Selected Procedures',
  orderDialogFinalTitle: 'Congratulations!',
  orderDialogLoginTitle: 'Authorization',

  orderDialogServicesNextText: 'Select Service',
  orderDialogMastersNextText: 'Select a cosmetologist',
  orderDialogTimesNextText: 'Select Time',
  orderDialogSumarryNextText: 'Add Service',
  orderDialogFinalNextText: 'Book Selected Services',
  
  orderDialogTimeMorning: 'Morning',
  orderDialogTimeAfternoon: 'Afternoon',
  orderDialogTimeEvening: 'Evening',
  orderDialogTimeNight: 'Night',

  orderDialogTimeNoItems: 'There is no available time on this day',

  profileNoteCreate: 'Make a note',
  profileNoteEdit: 'Edit',
  profileNoteEmpty: '<Empty>',
  profileNoteYour: 'Your notes:',
  
  save: 'Save',
  saved: 'Saved',
  edit: 'Edit',
  cancel: 'Cancel',
  send: 'Send',
  text: 'Text',
  email: 'E-mail',
  name: 'Name',
  sending: 'Sending...',
  feedbackWasAdded: 'Feedback was added!',
  yes: 'Yes',
  no: 'No',
  change: 'Change',
  delete: 'Delete',
  accept: 'Accept',
  reset: 'Reset',
  check: 'Check',
  
  
  footerWorkTimeTitle: 'Working hours',
  footerWorkTime: 'Every day from 9:00 to 21:00',
  footerContactsTitle: 'Contacts',
  footerNetworksTitle: 'We are in social networks',
  
  additionalRecomendations: 'Recommendations after the procedure',
  profileMasterSchedule: 'Cosmetologist\'s schedule',
  profileQuestionnaire: 'Questionnaire',
  profileQuestionnaireShow: 'View',
  profileEditTitle: 'Edit profile',
  profileFirstName: 'First Name',
  profileLastName: 'Last Name',
  profilePhone: 'Phone',

  giftCardsTitle1: 'You can purchase a gift certificate for any amount (starting from 250 zl) or service.',
  giftCardsTitle2: 'The certificate can be personalized without indicating its value',
  giftCardsTitle3: '(all details will be recorded separately in our database).',

  
  advicesItem1Title: 'How to take care of your facial skin in the spring-summer period?',
  advicesItem2Title: 'What are the benefits of enzymatic peeling?',
  advicesItem3Title: 'How to choose the right SPF cream?',
  advicesItem4Title: 'How to determine your skin type with a paper tissue?',
  advicesItem5Title: 'Stages of basic skin care',
  advicesItem6Title: 'Skin care around the eyes',

  advice1Text: `1.	For dry, oily, and combination skin, it is essential to cleanse in the morning and evening and use professional products selected by your cosmetologist.

    2.	For problematic skin types, it is necessary to use cosmetics with an active composition and a restoring effect.

    3.	Replacing foundation with BB/CC cream will be beneficial and will not clog pores.

    4.	Using SPF50 sunscreen cream against UVB and UVA radiation.

    5.	Moisturizing cream should have a light texture and absorb quickly.
  `,
  advice2Text: `Enzymatic peeling is a type of gentle exfoliation in which plant-based enzymes break down the layer of dead skin cells (essentially, the layer of dead cells that hinders the active functioning of all your home care products). Regular use of enzymatic peeling accelerates the renewal of the epidermis, thus helping our skin maintain its youthfulness. After enzymatic peeling, it is ideal to use facial masks. This way, they will truly penetrate the skin and work with maximum results. Masks should be used as additional care 1-2 times a week. Everything depends on the needs of your skin, the basis being: cleansing + moisturizing and nourishing.
  `,
  advice3Text: `When choosing a SPF cream, it is important to consider the level of protection it provides, namely:

  1.	SPF below 15 absorbs 50–75% of UV radiation;
  2.	SPF 15–20 provides high protection - 95%;
  3.	SPF 30–50 protects against 97% of UV radiation;
  4.	SPF 50+ offers the highest level of protection against UV radiation - 98%. 
  
  !!! SPF cream should be reapplied every 2–3 hours and after contact with water. UV radiation affects the skin even on cloudy days, so sunscreen should be used not only in summer but throughout the year.
  `,
  advice4Text: `1. Wash your face in the morning using your usual method, also use toner. Do not apply cream to your face.
  2. After 2 hours, take a regular dry paper tissue and gently blot your face. 
  
  Check the results: 
  
  Dry skin – if there are no traces on the paper; Combination – spots on the forehead, chin, cheeks, and nose; Normal – if there are light spots all over the tissue; Oily skin – intense spots all over the tissue. 
  
  Important: Home care is 90% of your success. No cosmetologist will help you if you disrupt the skin's protective barrier at home, over-dry it, use low-quality or unsuitable skincare products, etc.
  `,
  advice5Text: `Cleansing – one of the most important stages that is often overlooked or where serious mistakes are made. After all, for our creams and serums to work effectively, they should not be hindered by a layer of dirt and dust! The skin should be cleansed twice a day – in the morning and in the evening. Use products suitable for your skin type for cleansing. 
  
  Toning restores the skin's pH, which was disrupted by washing, and performs additional functions depending on the choice of toner. It can soothe or tighten pores, balance combination skin. Toning is done twice a day, after washing the face, on dry skin. 
  
  Finishing products – you can apply them to the skin even if the toner has not dried yet; in this case, it acts as a conductor. It is better to choose a professional line of finishing products, as it will really save your money. Plus, professional lines are much more concentrated in composition; they really work. Important: Serum should always be sealed with cream! Always. It is not a standalone product (except if the manufacturer explicitly stated it).  
  
  ! Important: Serum should always be sealed with cream! Always. It is not a standalone product (exception if the manufacturer explicitly stated it).
  `,
  advice6Text: `The skin around the eyes is thinner and more delicate and, of course, requires additional care. It also needs cleansing and toning. We recommend using a spray, as it is usually more comfortable. Just pour your favorite toner into a suitable spray container. Then apply a specially selected eye cream based on the needs of your skin. Please note that if the cream has a dense texture, it can cause swelling. This can be avoided if you apply the cream at least an hour before bedtime. This is only in case the swelling is caused by the density of the texture, not by an allergic reaction or any health problems. Mandatory additional care is a skincare routine, which should not be skipped or changed unless you are currently visiting a cosmetologist and have discussed this aspect with them, based on the procedures performed. We will consider a scenario where you are currently doing additional care yourself.
  `,

  feedbackDialogCreate: 'Leave feedback',
  feedbackAnswered: 'Remove from new',
  feedbackUnmarkAnswered: 'Add to new',
  feedbackIsNew: 'New feedback',
  feedbackCreatedAt: 'Created at',
  feedbackRemoveFromNew: 'Would you like to remove this feedback from New feedbacks?',
  feedbackRemoveFromAll: 'Do you want to move it to new feedbacks?',
  feedbacks: 'Feedbacks',
  newFeedbacks: 'New feedbacks',
  allFeedbacks: 'All feedbacks',
  showAllFeedbacks: 'Show all',
  noNewFeedbacks: 'No new feedbacks',

  workingTime: 'Working time',
  currentTime: 'Current time',
  addService: 'Add service',
  hasQuestions: 'Still have questions?',
  haveACall: 'Call',
  removeMaster: 'Remove Master',
  areYouSureToDeleteMaster: 'Are you sure you want to remove the cosmetologist?',
  confirmation: 'Confirmation',
  masterWasRemoved: 'Cosmetologist successfully removed',
  addWorkingTime: 'Add Working Time',
  cosmetologist: 'Cosmetologist',
  add: 'Add',
  done: 'Done',
  timeWasAdded: 'The time was added successfully!',
  startLabel: 'start time',
  endLabel: 'end time',

  source: "Came from",
  SourcePlatformUnknown: "Empty",
  SourcePlatformInst: "Instagram",
  SourcePlatformGoogle: "Google",
  SourcePlatformOther: "Other",

  authorize: "Authorization",
  authRestorePassword: "Restore password",
  authSignIn: "Sign in",
  authSignUp: "Sign up",
  authWithGoogle: "Continue with Google",
  authLogin: "Login",
  authFormEmail: "Email address",
  authFormPassword: "Password",
  authFormRepeatPassword: "Repeat password",
  authRegistration: "Registration",
  authRegister: "Register",
  or: "or",
  authBackToLogin: "Back to Sign in",
  authRestore: "Restore",
  authRestoreEmailSent: "A recovery email has been sent to your email.",
  accountInfoCode: "Enter the code",
  invitePageEnterCodeTitle: "Enter the code",
  codeForClientsAgreement: "Code to agree to the terms",
  codeForClientsAgreementMessage: "Code",
  inviteCodePageYourRecord: "Your record",
  inviteCodeDialogClientAccepted: "The client has already accepted the agreement",
  areYouSure: "Are you sure?",
  code: "Code",
  codeNotFound: "This code was not found, please try again with a different code.",
  selectUser: "Select a user",
  timeIsNotAvailable: "The selected time is already booked. Please try to choose a different time.",
  loginToBookRecord: "Please log in to your account to complete the booking.",
  newTimeForRecord: "New time for the record",
  newTimeMoveButton: "Move",
  controlPanelUsers: "Users",
  controlPanelUsersCount: "Users: ",
  filter: "Filter",
  search: "Search",
  filterRealUsersAll: "All",
  filterRealUsersReal: "Real",
  filterRealUsersFake: "Fake",
  codeConfirmationAccepted: "Successfully confirmed!",
  codeConfirmationDeleted: "The record was removed!",
  codeConfirmationTitle: "Record Confirmation",
  codeConfirmationNoRecord: "Record is not found",
  mainReviews: "Reviews",
  freeTimeOfMaster: "Available time",
  allMasters: "All",
}

export default en;