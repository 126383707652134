import { Avatar, IconButton, Typography } from "@mui/material";
import dayjs from "dayjs";
import styles from "./ScheduleInfo.module.css";
import { userDisplayName } from "../../../../../utils/formatUtils";
import ServiceWidget from "../../../../../components/widgets/profile/ServiceWidget";
import { useSelector } from "react-redux";
import TranslationTypes from "../../../../../constants/translationTypes";
import URLS from "../../../../../constants/urls";
import { Link } from "react-router-dom";
import { useState } from "react";
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import RecordNoteDialog from "../../../../../components/dialogs/ScheduleRecordsItem/RecordNote/RecordNoteDialog";
import RecordNote from "../../../../../components/dialogs/ScheduleRecordsItem/RecordNote";
import { useTranslation } from "react-i18next";


const ScheduleInfo = ({ info, masters, schedule }) => {
  const lang = useSelector(state => state.lang).title.toLowerCase();
  const { id, data } = info;
  const { t } = useTranslation();

  console.log("schedule", schedule);

  const master = masters.find(item => item.id === schedule.masterId);
  const times = [];
  if (data.length === 0) {
    times.push({
      startTime: schedule.startTime,
      endTime: schedule.endTime
    })
  } else {
    const sortedRecords = data.records.sort((a, b) => a.startTime - b.startTime)
    console.log("sortedRecords", sortedRecords);
    var start = schedule.startTime;
    sortedRecords.forEach(record => {
      if (start < record.startTime) {
        times.push({
          startTime: start,
          endTime: record.startTime
        })
        start = record.endTime;
      } else {
        start = record.endTime;
      }
    });
    if (start < schedule.endTime) {
      times.push({
        startTime: start,
        endTime: schedule.endTime
      })
    }
  }

  return <div className={styles.box}>
    <div className={styles.boxInfo}>
      <div className={styles.service}> 
        <Avatar src={ master?.imageUrl ?? "/img/no_photo.png" } sx={{ width: 36, height: 36 }} />
        <div>
          <Typography align="left">{ master?.name }</Typography>
          <Typography align="left">{ master?.surname }</Typography>
        </div>
      </div>

      <div>
        <Typography>{ t("freeTimeOfMaster") }</Typography>
        { times.map(timeRange => <Typography key={timeRange.startTime}>{ dayjs(timeRange.startTime).format("HH:mm") } - { dayjs(timeRange.endTime).format("HH:mm") }</Typography>) }
        { times.length === 0 && <Typography>-</Typography>}
      </div>
    </div>
  </div>
}

export default ScheduleInfo;